












































import { Component, Vue } from "vue-property-decorator";
import utils from "@/libs/utils";

@Component
export default class LoginLogs extends Vue {
  pagerHeader: any = {
    title: "登录日志",
    desc: "所有管理员登录日志",
    breadcrumb: ["系统管理", "登录日志"]
  };

  loading: boolean = false;
  keywords: string = "";
  filter: any = {
    keywords: "",
    page: 1,
    size: 15,
    userType: 1
  };
  tableData: any[] = [];
  async search() {
    this.filter.page = 1;
    this.filter.keywords = this.keywords;
    await this.getData();
  }
  async getData() {
    this.loading = true;
    try {
      let res = await this.$ajax.get("/api/services/app/User/GetLoginLogs", {
        params: this.filter
      });

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch {}

    this.loading = false;
  }
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.getData();
  }
  async created() {
    this.getData();
  }
}
